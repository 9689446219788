import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { Button, Card, CardBody, Progress } from 'reactstrap';
import { RoleContext } from 'context/Role';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  ACCOUNT_KEY,
  AGENT_KEY,
  BROKERAGE_DETAILS_KEY,
  BUSINESS_INFO_KEY,
  CUSTOM_URL_KEY,
  E_SIGN_KEY,
  FINAL_APPROVAL_KEY,
  LENDING_CRITERIA_KEY,
  MORTGAGE_ADMIN_KEY,
  PERSONAL_INFO_KEY,
  REJECTED_KEY
} from 'constant';
import { getRejectContents } from 'pages/Profile/Helper';
import {
  approveContents,
  completeProfileContents,
  getGetStartedCode,
  getProgress,
  getSecondRouteOfRole,
  profileContents,
  usersContent
} from './Helper';

const Contents = ({ userPersonalDetails, isTab, investorKey, setInvestorKey }) => {
  const navigate = useNavigate();
  const role = useContext(RoleContext);
  const { id, title } = useParams();
  const { pathname } = useLocation();
  const completeProfileParam = pathname.split('/')[pathname.split('/').length - 1];

  const {
    isEmailVerified,
    isPersonalInfo,
    isBrokerageInfo,
    isBusinessInfo,
    isLendingCreteria,
    isAccountVerified,
    approveSections,
    approveRequiredSections
  } = userPersonalDetails;

  const onStepping = async (item) => {
    if (setInvestorKey && investorKey) {
      await setInvestorKey((prevKey) => investorKey + 1);
    }
    if (pathname === '/dashboard') return null;
    if (pathname.includes('complete-profile')) {
      return navigate(`/complete-profile/${item.path}`);
    }
    if (pathname.includes('approval')) {
      return navigate(`/approval/${title}/${id}/${item.path}`);
    }
    if (pathname.includes('user')) {
      return navigate(`/user/${title}/${id}/${item.path}`);
    }
    if (pathname.includes('profile')) {
      return navigate(`/profile/${item.path}`);
    }
  };

  const fetchHeading = () => {
    return pathname === '/dashboard' ? 'Complete your Profile' : 'Contents';
  };

  const isDetailSubmited = (item) => {
    return (
      (item.key === 'email-confirmation' && isEmailVerified) ||
      (item.key === 'personal-detial' && isPersonalInfo) ||
      (item.key === 'brokerage-details' && isBrokerageInfo) ||
      (item.key === 'business-information' && isBusinessInfo) ||
      (item.key === 'lending-criteria' && isLendingCreteria) ||
      (item.key === 'personal-information' && isPersonalInfo) ||
      (item.key === 'accounts' && isAccountVerified)
    );
  };

  const getProfileContent = () => {
    if (pathname.includes('approval')) {
      return approveContents[title || role.roleKey];
    }
    if (pathname.includes('complete-profile') || pathname.includes('dashboard')) {
      return completeProfileContents[title || role.roleKey];
    }
    if (pathname.includes('user')) {
      return usersContent[title];
    }
    if (pathname.includes('profile')) {
      return profileContents[role.roleKey];
    }
  };

  const profileContentList = getProfileContent();

  const isApprovedSections = (item) => {
    const { key } = item;
    const sections = approveSections ? approveSections : {};

    const {
      personal_information,
      final_approval,
      e_sign,
      custom_url,
      brokerage_details,
      account,
      lending_criteria,
      business_info,
      mortgage_admin
    } = sections;
    return (
      (key === PERSONAL_INFO_KEY && personal_information) ||
      (key === BROKERAGE_DETAILS_KEY && brokerage_details) ||
      (key === CUSTOM_URL_KEY && custom_url) ||
      (key === E_SIGN_KEY && e_sign) ||
      (key === FINAL_APPROVAL_KEY && final_approval) ||
      (key === ACCOUNT_KEY && account) ||
      (key === LENDING_CRITERIA_KEY && lending_criteria) ||
      (key === BUSINESS_INFO_KEY && business_info) ||
      (key === MORTGAGE_ADMIN_KEY && mortgage_admin)
    );
  };

  const getContentsChecked = (item) => {
    return pathname.includes('approval') ? isApprovedSections(item) : isDetailSubmited(item);
  };

  const renderChecksIcons = (item) => {
    const isProfile = pathname.includes('user') || pathname.includes('profile');
    if (isProfile && !pathname.includes('complete-profile')) {
      return null;
    }
    if (getContentsChecked(item)) {
      let textPrimary = '';

      if (approveRequiredSections && !approveRequiredSections[item.key]) {
        textPrimary = 'text-primary';
      }
      return (
        <FontAwesomeIcon
          icon={faCircleCheck}
          color="#FDAC40"
          className={`${textPrimary} font-size-20`}
        />
      );
    }
    if (
      id &&
      title &&
      pathname.includes('approval') &&
      typeof getRejectContents(item, approveSections) === 'boolean'
    ) {
      return <FontAwesomeIcon icon={faCircleXmark} color="#EA6666" className="font-size-20" />;
    } else {
      return <FontAwesomeIcon icon={faCircleCheck} color="#bcbdc2" className="font-size-20" />;
    }
  };

  const onHandleGetStarted = () => {
    if (userPersonalDetails.approvedStatus === REJECTED_KEY) {
      const route = getSecondRouteOfRole(role.roleKey);
      return navigate(route);
    }
    if (role.roleTitle === AGENT_KEY && userPersonalDetails.requestStatus === REJECTED_KEY) {
      return navigate(`/complete-profile/brokerage`);
    }
    if (!userPersonalDetails.isEmailVerified) {
      return navigate(`/complete-profile/email-verify`);
    }
    let newPath = getGetStartedCode(userPersonalDetails, role.roleTitle);
    return navigate(newPath);
  };

  return (
    <div
      className="side-content"
      style={pathname.includes('user') || pathname.includes('approval') ? { top: '101px' } : {}}>
      <Card>
        <CardBody className={`${isTab ? 'mt-2 p-0' : 'p-0'}`}>
          {!isTab ? <h3 className="p-4 mt-1 mb-0 pb-3 mx-1">{fetchHeading()}</h3> : null}
          {pathname === '/dashboard' && (
            <div className="mb-3 px-4 mt-2 mx-1">
              <Progress
                color="primary"
                className="progress-height"
                value={getProgress(userPersonalDetails, role.roleTitle)}
              />
            </div>
          )}
          {profileContentList?.map((item) => {
            return (
              <div
                onClick={() => onStepping(item)}
                key={item.name}
                className={
                  completeProfileParam === item.path
                    ? 'py-1 selected-border mb-1 pe-1'
                    : 'py-1 mb-1 px-1'
                }>
                <div className="d-flex justify-content-between px-4 align-items-center">
                  <p
                    className={
                      completeProfileParam === item.path
                        ? 'text-primary mb-0 font-size-16 cursor-pointer semi-bold'
                        : `text-muted mb-0 font-size-16 ${
                            pathname !== '/dashboard' ? 'cursor-pointer' : ''
                          }`
                    }>
                    <span className="semi-bold">{item.name}</span>
                  </p>
                  {renderChecksIcons(item)}
                </div>
              </div>
            );
          })}
          {pathname.includes('dashboard') ? (
            <div className="p-4 mx-1">
              <Button color="primary" className="w-100" onClick={onHandleGetStarted} size={'lg'}>
                {userPersonalDetails.isEmailVerified ? 'Continue' : 'Get Started'}
              </Button>
            </div>
          ) : (
            <div className="mb-3" />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default Contents;
